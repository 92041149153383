

body{
    background-image: url("https://wallpapercave.com/wp/FJShZJ9.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0px;

}
#in_site_redirectors{
    display:flex;  
    list-style:none;
}

#navigation_element{
    margin-right: 40px;
}

#title {
    
    font-size: 50px;
    color:blue;
    text-align: center;

}

#explanation_text {
    font-size: 25px;
    color: rgb(255, 0, 0);
    text-align: center;
    width: 40%;
    padding-left: 30%;
}

#sign_in_title{
    font-size: 30px;
    color: rgb(207, 3, 3);
    text-align: center;
    width: 100%;
    padding-bottom: 40px;
}

.popup{
    border: none;
}


.popup .popup_content {
    width: 300px;
    height: 400px;
    z-index: 2;
    text-align: center;
    padding: 20px;
    border: none;
    border-radius: 20px;
    background: #1c1c1c;
    box-shadow:  38px 38px 56px #1e1e1e, 
                -25px -25px 38px #1e1e1e;
                

}


.popup .input_field{
    position: relative;
    width: 100%;
    height: 40px;

    font-size: 16px;
    color: rgb(255, 255, 255);
    margin-bottom: 10px;

    background-color: rgb(59, 59, 59);
    border: none;
    border-radius: 4px;

}

.popup .sign_in_button{
    
    width: 200px;
    margin-top: 50px;

    font-size: 25px;

    color: white;
    background-color: black;
    border: none;

    border-radius: 15px;
}

.popup_bottom_text{
    width: 100%;
    color: white;
    position: absolute;

    left: 50%;
    transform: translate(-50%, 0%);

    bottom: 0;
    padding-bottom: 0%;

}

.login_close_button {
    position: absolute;
    text-decoration: none;
    top: 0;
    right: 10px;
    font-size: 40px;
    margin: 0%;
    padding: 0%;
}
.login_close_button:hover{
    color: white;
}


#login{
    position: absolute;
    right: 0;
    top: 0;
}

.blur {
    filter: blur(5px);
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
}