.disable_link_highlight {   
    cursor: default;
    text-decoration: none;
    color: green;
}

.disable_link_highlight:hover{
    cursor: pointer;
}

.center_of_parent{
    display: grid;
    justify-content: center;
}

.relative_absolute_center_of_parent{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.absolute_center_of_parent{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.normal_paragraph{
    font-family: 'Roboto', sans-serif;
    white-space: pre-line;
}

.another_thing{
    margin: 0;
    padding: 0;
    color: aqua;
}

.lumi_logo{
    position: fixed;
    /* size of the image + element */
    width: 100%;

    /* center the image */
    margin-left: auto;
    margin-right: auto;

    /*-*/
    overflow: hidden;
    
    /*@TODO: Find a better way to center it.*/
    position: relative;
    left: 50%;
}


.lumi_logo_img{
    position: fixed;
    left: 43%;
    max-width: 50%;
    
}
.side_panel  {
    width: 0;
    position: fixed;
    z-index: 1;
    height: auto;
    max-height: 70%;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.side_panel a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}


.side_panel .close_button {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 40px;
    margin: 0%;
    padding: 0%;
}
.side_panel a:hover {
    color: #f1f1f1;
}
.side_panel .close_button:hover{
    color: #f1f1f1;
    cursor: pointer;
}

.open_sidepanel_button {
    font-size: 20px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
}

.open_sidepanel_button:hover {
    background-color:#444;
}


.not_logged_in_image {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    
}
